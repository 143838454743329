import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class FilterStateChangedEvent implements IEvent {
	public static readonly EventType = 'SearchFilters:FilterStateChangedEvent';

	public readonly timestamp = new Date();
	public readonly eventType = FilterStateChangedEvent.EventType;

	public static is(event: IEvent): event is FilterStateChangedEvent {
		return event.eventType === FilterStateChangedEvent.EventType;
	}
}
