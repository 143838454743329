import { IEventAggregationService } from "@studyportals/event-aggregation-service-interface";
import { FilterTag } from "./FilterTag";
import { FilterKey } from "../FilterKey";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { ClearFiltersRequestedEvent } from "@/events/ClearFiltersRequestedEvent";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class ClearFilterTag extends FilterTag {

	private readonly eventAggregationService: IEventAggregationService;

	public constructor(
		key: FilterKey,
		values: readonly string[],
		required: boolean,
		disabled: boolean,
		filterProvider: IFilterProviderEnhanced,
		eventAggregationService?: IEventAggregationService
	) {
		super(key, values, required, disabled, filterProvider);

		this.eventAggregationService = eventAggregationService ?? EventAggregationService.instance;
	}

	public async doClick(): Promise<void> {
		await this.clear();
	}

	protected async clear(): Promise<void> {
		if (this.disabled) {
			return;
		}

		await this.clearFilter();
	}

	protected async clearFilter(): Promise<void> {
		const payload = this.values.map((value) => {
			return { key: this.key, value };
		});

		this.eventAggregationService.publishTo(ClearFiltersRequestedEvent.EventType, new ClearFiltersRequestedEvent(payload));

		return Promise.resolve();
	}
}
