import { inject } from 'vue';
import { FilterProviderKey } from './FilterProviderKey';
import { FilterProviderEnhanced } from './FilterProviderEnhanced';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';

const injectExternal = <T>(key: FilterProviderKey): T => {
	const value = inject<T>(key);
	if (value === undefined) {
		throw new Error(`"${key}" was not provided`);
	}

	if (key === FilterProviderKey.FILTER_PROVIDER) {
		return new FilterProviderEnhanced(value as IFilterProvider) as T;
	}

	return value;
};

export { injectExternal as InjectExternal };
