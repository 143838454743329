import { FilterKey } from "@/models/FilterKey";
import { computed, ref, Ref, nextTick } from "vue";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { SuggestionFactory } from "@/models/suggestions/SuggestionFactory";
import { Suggestion } from "@/models/suggestions/Suggestion";
import { Tag } from "@/models/tags/Tag";
import { ISubscriber } from "@studyportals/event-aggregation-service-interface";
import { EventAggregationServiceSubscription } from "@/platform/EventAggregationServiceSubscription";
import { EventAggregationService } from "@/platform/EventAggregationService";
import { FilterStateChangedEvent } from "@/events/FilterStateChangedEvent";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export default class SelectionSuggestion implements ISubscriber<FilterStateChangedEvent> {

	public suggestion: Ref<Suggestion<Tag> | null> = ref(null);
	public count: Ref<number | null> = ref(null);
	public tagRef: Ref<{ tagElement: HTMLElement; } | null> = ref(null);

	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;

	public constructor(
		private readonly filterProvider: IFilterProviderEnhanced,
		private readonly locationFilterProvider: ILocationFilterProvider,
		private readonly key: FilterKey
	) {
		this.eventAggregationServiceSubscription = EventAggregationService.instance
			.subscribe(this)
			.to(FilterStateChangedEvent.EventType);
	}

	public async onMounted(): Promise<void> {
		await this.manageSuggestion();
	}

	public async notify(event: FilterStateChangedEvent): Promise<void> {
		if (FilterStateChangedEvent.is(event)) {
			await this.manageSuggestion();
		}
	}

	public async apply(event?: Event): Promise<void> {
		if (event) {
			event.stopPropagation();
		}
		await nextTick();
		this.tagRef.value?.tagElement?.click();
	}

	public canShow = computed(() => this.suggestion.value !== null && this.suggestion.value.tag !== null);

	private async manageSuggestion(): Promise<void> {
		await nextTick();
		this.suggestion.value = null;
		this.count.value = null;

		const suggestionFactory = new SuggestionFactory(this.filterProvider, this.locationFilterProvider, this.key);
		const suggestion = suggestionFactory.get();

		if (suggestion === null || suggestion.tag === null) {
			return;
		}

		const count = await suggestion.getResultsCount();
		if (count === 0) {
			return;
		}

		this.count.value = count;
		this.suggestion.value = suggestion;
	}

	public onUnmounted(): void {
		this.eventAggregationServiceSubscription.dispose();
	}
}
