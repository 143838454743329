import { defineComponent, onMounted, onUnmounted, Component, PropType } from 'vue';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { SelectedFiltersTracking } from './SelectedFilters.tracking';
import SelectedFilters from './SelectedFilters.class';
import Tracking from '../tracking/Tracking.vue';
import Tag from '../tag/Tag.vue';
import SelectedFiltersSkeletonLoader from './skeleton-loader/SelectedFiltersSkeletonLoader.vue';
import { ISelectedFiltersOptions } from './ISelectedFIltersOptions';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { InjectExternal } from '@/platform/InjectExternal';
import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

interface ISelectedFiltersProps {
	options: ISelectedFiltersOptions;
	displaySection?: string;
}

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
		Tag: Tag as Component,
		SelectedFiltersSkeletonLoader: SelectedFiltersSkeletonLoader as Component,
	},
	props: {
		options: {
			type: Object as PropType<ISelectedFiltersOptions>,
			required: false,
			default: {
				displayClearAll: true,
				collapseToDefaultNumberOfRows: true,
				displayTitle: true
			}
		},
		displaySection: {
			type: String,
			required: false
		}
	},
	setup: (props: ISelectedFiltersProps) => {

		const { displayClearAll, collapseToDefaultNumberOfRows, displayTitle } = props.options;

		const filterProvider = InjectExternal<IFilterProviderEnhanced>(FilterProviderKey.FILTER_PROVIDER);
		const locationFilterProvider = InjectExternal<ILocationFilterProvider>(FilterProviderKey.LOCATION_FILTER_PROVIDER);

		const component = new SelectedFilters(filterProvider, locationFilterProvider);

		const tracking = new SelectedFiltersTracking(component, props.displaySection);

		onMounted(component.onMounted.bind(component));
		onUnmounted(component.onUnmounted.bind(component));

		return {
			tags: component.tags,
			filtersApplied: component.filtersApplied,
			removableFiltersCount: component.removableFiltersCount,
			filterTagContainerRef: component.tagContainer.tagContainerRef,
			clearAllButtonRef: component.clearAllButtonRef,
			shouldDisplayShowMore: component.tagContainer.shouldDisplayShowMore,
			shouldDisplayShowLess: component.tagContainer.shouldDisplayShowLess,
			clearAllButtonTrackingData: tracking.clearAllTrackingData,
			tagTrackingPayload: tracking.tagTrackingPayload,
			isBusy: component.busyIndicator.isBusy,
			showMore: component.tagContainer.showMore.bind(component),
			showLess: component.tagContainer.showLess.bind(component),
			clearAll: component.clearAll.bind(component),
			displayClearAll,
			displayTitle,
			displayHeader: displayClearAll || displayTitle,
			collapseToDefaultNumberOfRows
		};
	},
});
