import { FilterKey } from "../FilterKey";
import { Suggestion } from "./Suggestion";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { computed, ComputedRef, ref } from "vue";
import { ITrackingPayload } from "@/presentation/tracking/ITrackingPayload";
import { Action } from "@studyportals/datalake-event-tracker";
import { FilterHierarchyFactory } from "../hierarchy/FilterHierarchyFactory";
import { ISuggestionPayload } from "./ISuggestionPayload";
import { SuggestionFilterTag } from "../tags/SuggestionFilterTag";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";
import { IFilterHierarchy } from "../hierarchy/IFilterHierarchy";

export class FilterSuggestion extends Suggestion<SuggestionFilterTag> {

	private readonly locationFilterProvider: ILocationFilterProvider;
	private readonly filterHierarchy: IFilterHierarchy;
	private readonly key: FilterKey;
	private readonly parentKey: FilterKey | null;

	public constructor(
		filterProvider: IFilterProviderEnhanced,
		locationFilterProvider: ILocationFilterProvider,
		key: FilterKey
	) {
		super(filterProvider);

		this.locationFilterProvider = locationFilterProvider;
		this.key = key;
		const filterHierarchyFactory = new FilterHierarchyFactory(filterProvider, locationFilterProvider);
		this.filterHierarchy = filterHierarchyFactory.getFor(key);
		this.parentKey = this.filterHierarchy.getParentKey();
	}

	protected buildSuggestionTag(): SuggestionFilterTag | null {
		const selection = this.filterProvider.getFilterSelection(this.key);
		if (selection.length === 0) {
			return null;
		}

		const parent = this.filterHierarchy.getParentValueFor(selection[0]);
		if (parent === null) {
			return null;
		}
		return new SuggestionFilterTag(parent.key, [parent.value], this.filterProvider, this.locationFilterProvider);
	}

	protected buildSuggestionPayload(): ISuggestionPayload {
		if (this.tag === null) {
			throw new Error('FilterSuggestion: tag is null');
		}

		return {
			toSelect: [this.tag.toSelect],
			toDeselect: this.tag.toDeselect
		};
	}

	protected getTagIcon(): string {
		const tagIconMapping: { [key: string]: string; } = {
			[FilterKey.DISCIPLINES]: 'lnr-arrow-right',
			[FilterKey.COUNTRY]: 'lnr-map-marker',
			[FilterKey.AREA]: 'lnr-map-marker',
			[FilterKey.CONTINENT]: 'lnr-map-marker',
		};

		return tagIconMapping[this.key];
	}

	public trackingPayload: ComputedRef<ITrackingPayload> = computed(() => {
		return {
			dataRef: ref(this.tag?.values.join(',') ?? ''),
			action: ref(Action.SELECT),
			label: `suggestion|${this.parentKey ?? ''}`
		};
	});
}
