import { FilterKey } from "../FilterKey";
import { ClearFilterTag } from "./ClearFilterTag";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class KeywordClearFilterTag extends ClearFilterTag {
	public constructor(
		public readonly key: FilterKey.KEYWORD_WHAT | FilterKey.KEYWORD_WHERE,
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProviderEnhanced
	) {
		super(key, values, required, disabled, filterProvider);
	}

	protected override getDisplayNameForSingleValue(value: string): string {
		return `"${value}"` || '';
	}

	public override async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilter(this.key);
	}
}
