import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterHierarchy } from "./FilterHierarchy";
import { FilterKey } from "../FilterKey";
import { getContinentsForCountry } from "../CountryContinentMapping";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";

export class CountryHierarchy extends FilterHierarchy {

	public constructor(
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider
	) {
		super(FilterKey.COUNTRY, FilterKey.CONTINENT, [FilterKey.AREA], filterProvider, locationFilterProvider);
	}

	public getSelectedChildrenForAllLevels(value: string): IFilterPayload[] {
		const areasForCountry = this.locationFilterProvider.identifyAreasFor(value);
		return this.filterProvider
			.getFilterSelection(FilterKey.AREA)
			.filter(area => areasForCountry.includes(area))
			.map(area => ({ key: FilterKey.AREA, value: area }));
	}

	public getParentValueFor(value: string): IFilterPayload | null {
		const continents = getContinentsForCountry(value);

		if (!continents || continents.length === 0) {
			return null;
		}

		return {
			key: FilterKey.CONTINENT,
			value: continents[0]
		};
	}
}
