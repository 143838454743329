import { Component, defineComponent, onMounted, onUnmounted, PropType } from "vue";
import { FilterProviderKey } from "@/platform/FilterProviderKey";
import { InjectExternal } from "@/platform/InjectExternal";
import { FilterKey } from "@/models/FilterKey";
import Tag from "../../tag/Tag.vue";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import SelectionSuggestion from "./SelectionSuggestion.class";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export default defineComponent({
	components: {
		Tag: Tag as Component
	},
	props: {
		selectionKey: {
			type: String as PropType<FilterKey>,
			required: true
		}
	},
	setup: (props) => {

		const filterProvider = InjectExternal<IFilterProviderEnhanced>(FilterProviderKey.FILTER_PROVIDER);
		const locationFilterProvider = InjectExternal<ILocationFilterProvider>(FilterProviderKey.LOCATION_FILTER_PROVIDER);

		const component = new SelectionSuggestion(filterProvider, locationFilterProvider, props.selectionKey);
		onMounted(() => component.onMounted());
		onUnmounted(() => component.onUnmounted());

		return {
			suggestion: component.suggestion,
			count: component.count,
			tagRef: component.tagRef,
			canShow: component.canShow,
			apply: component.apply.bind(component)
		};
	}
});
