import { Ref, ref } from "vue";

export class DisciplineSuggestions {
	public hasSuggestions = ref(false);
	public suggestionsRef: Ref<HTMLElement | null> = ref(null);

	private observer: MutationObserver;

	public onMounted(): void {
		if (this.suggestionsRef.value) {
			this.updateSuggestions();

			this.observer = new MutationObserver(() => {
				this.updateSuggestions();
			});

			this.observer.observe(this.suggestionsRef.value, { childList: true });
		}
	}

	public onUnmounted(): void {
		this.observer?.disconnect();
	}

	private updateSuggestions(): void {
		const childrenCount = this.suggestionsRef?.value?.children?.length ?? 0;
		this.hasSuggestions.value = childrenCount > 0;
	}
}
