import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterHierarchy } from "./FilterHierarchy";
import { FilterKey } from "../FilterKey";
import { getContinentsForCountry } from "../CountryContinentMapping";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";

export class ContinentHierarchy extends FilterHierarchy {

	public constructor(
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider
	) {
		super(FilterKey.CONTINENT, null, [FilterKey.COUNTRY, FilterKey.AREA], filterProvider, locationFilterProvider);
	}

	public getSelectedChildrenForAllLevels(value: string): IFilterPayload[] {
		const selectedCountriesOfContinent = this.filterProvider
			.getFilterSelection(FilterKey.COUNTRY)
			.filter(country => getContinentsForCountry(country)?.includes(value));

		return [
			...selectedCountriesOfContinent.map(country => ({ key: FilterKey.COUNTRY, value: country })),
			...selectedCountriesOfContinent.flatMap(this.getSelectedAreasForCountry.bind(this))
		];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getParentValueFor(_value: string): IFilterPayload | null {
		return null;
	}

	private getSelectedAreasForCountry(country: string): IFilterPayload[] {
		const areasForCountry = this.locationFilterProvider.identifyAreasFor(country);
		return this.filterProvider
			.getFilterSelection(FilterKey.AREA)
			.filter(area => areasForCountry.includes(area))
			.map(area => ({ key: FilterKey.AREA, value: area }));
	}

}
