import { FilterKey } from "@/models/FilterKey";
import SelectionSuggestion from "../selection-suggestion/SelectionSuggestion.vue";
import { Component, defineComponent, onMounted, onUnmounted } from "vue";
import { LocationSuggestions } from "./LocationSuggestions.class";


export default defineComponent({
	components: {
		SelectionSuggestion: SelectionSuggestion as Component
	},
	props: {
		title: {
			type: String,
			default: 'Or expand your search location'
		}
	},
	setup: (props) => {

		const component = new LocationSuggestions();

		onMounted(() => component.onMounted());
		onUnmounted(() => component.onUnmounted());

		return {
			suggestionsRef: component.suggestionsRef,
			hasSuggestions: component.hasSuggestions,
			regionFilterKey: FilterKey.AREA,
			countryFilterKey: FilterKey.COUNTRY,
			continentFilterKey: FilterKey.CONTINENT,
			title: props.title
		};
	}
});
