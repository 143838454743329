import { FilterKey } from "@/models/FilterKey";
import SelectionSuggestion from "../selection-suggestion/SelectionSuggestion.vue";
import { Component, defineComponent, onMounted, onUnmounted } from "vue";
import { DisciplineSuggestions } from "./DisciplineSuggestions.class";


export default defineComponent({
	components: {
		SelectionSuggestion: SelectionSuggestion as Component
	},
	props: {
		title: {
			type: String,
			default: 'View more results by searching for'
		}
	},
	setup: (props) => {

		const component = new DisciplineSuggestions();

		onMounted(() => component.onMounted());
		onUnmounted(() => component.onUnmounted());

		return {
			suggestionsRef: component.suggestionsRef,
			hasSuggestions: component.hasSuggestions,
			disciplineFilterKey: FilterKey.DISCIPLINES,
			title: props.title
		};
	}
});
