import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

export abstract class Tag {
	public readonly required: boolean;
	public readonly disabled: boolean;

	private _id: string;
	private _displayName: string;

	protected readonly filterProvider: IFilterProviderEnhanced;

	public constructor(
		required: boolean,
		disabled: boolean,
		filterProvider: IFilterProviderEnhanced,
	) {
		this.required = required;
		this.disabled = disabled;
		this.filterProvider = filterProvider;
	}

	public get id(): string {
		if (!this._id) {
			this._id = this.constructId();
		}
		return this._id;
	}

	public get displayName(): string {
		if (!this._displayName) {
			this._displayName = this.constructDisplayName();
		}
		return this._displayName;
	}

	public async onClick(event: Event): Promise<void> {
		event.stopPropagation();

		if (this.disabled) {
			return Promise.resolve();
		}

		await this.doClick();
	}

	protected abstract doClick(): Promise<void>;

	protected abstract constructId(): string;

	protected abstract constructDisplayName(): string;
}
