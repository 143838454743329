import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterKey } from "../FilterKey";
import { IFilterHierarchy } from "./IFilterHierarchy";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";

export abstract class FilterHierarchy implements IFilterHierarchy {

	public constructor(
		protected readonly key: FilterKey,
		protected readonly parentKey: FilterKey | null,
		protected readonly childrenKeys: FilterKey[],
		protected readonly filterProvider: IFilterProvider,
		protected readonly locationFilterProvider: ILocationFilterProvider
	) { }

	public canHaveParent(): boolean {
		return this.parentKey !== null;
	}

	public canHaveChildren(): boolean {
		return this.childrenKeys.length > 0;
	}

	public getParentKey(): FilterKey | null {
		return this.parentKey;
	}

	public getChildrenKeys(): FilterKey[] {
		return this.childrenKeys;
	}

	public abstract getSelectedChildrenForAllLevels(value: string): IFilterPayload[];

	public abstract getParentValueFor(value: string): IFilterPayload | null;
}
