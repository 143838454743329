import { ClearFiltersRequestedEvent } from '@/events/ClearFiltersRequestedEvent';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';
import { EventAggregationService } from '@/platform/EventAggregationService';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { EventAggregationServiceSubscription } from '@/platform/EventAggregationServiceSubscription';
import { IFilterPayload } from '@/interfaces/IFilterPayload';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { FilterHierarchyFactory } from './hierarchy/FilterHierarchyFactory';

export class ClearFilters implements ISubscriber<ClearFiltersRequestedEvent> {

	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;
	private readonly filterProvider: IFilterProvider;
	private readonly locationFilterProvider: ILocationFilterProvider;

	public constructor(
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider,
		eventAggregationService?: EventAggregationService
	) {
		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;

		eventAggregationService ??= EventAggregationService.instance;
		this.eventAggregationServiceSubscription = eventAggregationService.subscribe(this).to(ClearFiltersRequestedEvent.EventType);
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
	}

	public async notify(event: ClearFiltersRequestedEvent): Promise<void> {
		if (ClearFiltersRequestedEvent.is(event)) {
			await this.clearFilters(event);
		}
	}

	public clearFilters = async (event: ClearFiltersRequestedEvent): Promise<void> => {

		const filterHierarchyFactory = new FilterHierarchyFactory(this.filterProvider, this.locationFilterProvider);

		const filtersToClear: IFilterPayload[] = event.filters;

		event.filters.forEach(filter => {
			const filterHierarchy = filterHierarchyFactory.getFor(filter.key);
			if (!filterHierarchy?.canHaveChildren()) {
				return;
			}
			const selectedChildren = filterHierarchy.getSelectedChildrenForAllLevels(filter.value);
			filtersToClear.push(...selectedChildren);
		});

		await this.filterProvider.clearSelection(filtersToClear);
	};

	public clearAll = async (filterKeys: FilterKey[]): Promise<void> => {
		await this.filterProvider.clearFilters(filterKeys);
	};
}
