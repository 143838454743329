import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterKey } from "../FilterKey";
import { FilterSuggestion } from "./FilterSuggestion";
import { AllAroundTheWorldSuggestion } from "./AllAroundTheWorldSuggestion";
import { Suggestion } from "./Suggestion";
import { Tag } from "../tags/Tag";
import { FilterHierarchyFactory } from "../hierarchy/FilterHierarchyFactory";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class SuggestionFactory {

	private readonly suggestion: Suggestion<Tag> | null = null;

	public constructor(
		filterProvider: IFilterProviderEnhanced,
		locationFilterProvider: ILocationFilterProvider,
		key: FilterKey
	) {
		if (key === FilterKey.CONTINENT) {
			this.suggestion = new AllAroundTheWorldSuggestion(filterProvider);
			return;
		}

		const filterHierarchyFactory = new FilterHierarchyFactory(filterProvider, locationFilterProvider);
		const filterHierarchy = filterHierarchyFactory.getFor(key);

		if (filterHierarchy?.canHaveParent() !== null) {
			this.suggestion = new FilterSuggestion(filterProvider, locationFilterProvider, key);
		}
	}

	public get(): Suggestion<Tag> | null {
		return this.suggestion;
	}
}
