import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "../FilterKey";
import { FilterHierarchy } from "./FilterHierarchy";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { IFilterPayload } from "@/interfaces/IFilterPayload";

export class NoHierarchy extends FilterHierarchy {

	public constructor(
		key: FilterKey,
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider
	) {
		super(key, null, [], filterProvider, locationFilterProvider);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getSelectedChildrenForAllLevels(_value: string): IFilterPayload[] {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getParentValueFor(_value: string): IFilterPayload | null {
		return null;
	}

}
