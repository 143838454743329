import { ICurrencyFilterProvider } from '../interfaces/filter-providers/ICurrencyFilterProvider';
import { IFilterProvider } from '../interfaces/filter-providers/IFilterProvider';
import { ILocationFilterProvider } from '../interfaces/filter-providers/ILocationFilterProvider';
import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { ProgrammeSearchInput  } from '@studyportals/search-api-interface';

export class FilterValuesChangedEvent implements IEvent {
	public static readonly EventType = 'SearchFilters:FilterValuesChangedEvent';

	public readonly timestamp = new Date();
	public readonly eventType = FilterValuesChangedEvent.EventType;
	public readonly programmeSearchInput: ProgrammeSearchInput;
	public readonly filterProvider: IFilterProvider & ILocationFilterProvider & ICurrencyFilterProvider;

	public constructor(
		programmeSearchInput: ProgrammeSearchInput,
		filterProvider: IFilterProvider & ILocationFilterProvider & ICurrencyFilterProvider
	) {
		this.programmeSearchInput = programmeSearchInput;
		this.filterProvider = filterProvider
	}

	public static is(event: IEvent): event is FilterValuesChangedEvent {
		return event.eventType === FilterValuesChangedEvent.EventType;
	}
}
