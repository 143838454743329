import { defineComponent, ref, toRef, watch, Ref, PropType, Component } from 'vue';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { ITrackingInput } from '@/presentation/tracking/ITrackingInput';
import { ITrackingPayload } from '../tracking/ITrackingPayload';
import Tracking from '../tracking/Tracking.vue';
import { Tag } from '@/models/tags/Tag';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
	},
	props: {
		tag: {
			type: Object as PropType<Ref<Tag>>,
			required: true,
		},
		icon: {
			type: String,
			default: '',
		},
		trackClick: {
			type: Boolean,
			required: true
		},
		trackingPayload: {
			type: Object as PropType<ITrackingPayload | null>,
			default: null
		}
	},
	setup: (props) => {

		if (props.trackClick === true && props.trackingPayload === null) {
			throw new Error('trackingPayload is required when trackClick is true for Tag component');
		}
		if (props.trackClick === false && props.trackingPayload !== null) {
			throw new Error('trackingPayload is not allowed when trackClick is false for Tag component');
		}

		const tag = toRef(props, 'tag');

		const tagRef = ref<HTMLElement | null>(null);

		const trackingData: Ref<ITrackingInput | null> = ref(null);
		const trackingPayload = props.trackingPayload;

		const maintainTrackingInput = (): void => {
			if (trackingPayload === null || tag.value.disabled) {
				trackingData.value = null;
				return;
			}

			trackingData.value = new TrackingInput(tagRef, trackingPayload.dataRef, trackingPayload.action, ControlEvent.CLICK, trackingPayload.label);
		};

		watch(tag, () => maintainTrackingInput());
		maintainTrackingInput();

		return {
			tag,
			tagRef,
			trackingData,
			iconClass: props.icon,
			tagElement: tagRef
		};
	},
});
