import { Tag } from "./Tag";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class CustomTag extends Tag {

	public constructor(
		required: boolean,
		disabled: boolean,
		filterProvider: IFilterProviderEnhanced,
		private readonly displayText: string,
		private readonly customClick: () => Promise<void>
	) {
		super(required, disabled, filterProvider);
	}

	public override async doClick(): Promise<void> {
		await this.customClick();
	}

	protected constructId(): string {
		return this.displayName.replace(/[^a-zA-Z0-9]/g, "");
	}

	protected constructDisplayName(): string {
		return this.displayText;
	}
}
