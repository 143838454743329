import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterHierarchy } from "./FilterHierarchy";
import { FilterKey } from "../FilterKey";
import { DisciplineFilterOptionInformation } from "../DisciplineFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterOptionInformation } from "../FilterOptionInformation";

export class DisciplineHierarchy extends FilterHierarchy {

	public constructor(
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider
	) {
		super(FilterKey.DISCIPLINES, FilterKey.DISCIPLINES, [FilterKey.DISCIPLINES], filterProvider, locationFilterProvider);
	}

	public getSelectedChildrenForAllLevels(value: string): IFilterPayload[] {
		const selected = this.filterProvider.getFilterSelection(FilterKey.DISCIPLINES);
		if (selected.length === 0) {
			return [];
		}

		const optionInformation = this.filterProvider.getFilterOptionInformationById(FilterKey.DISCIPLINES, value);
		const isSubDiscipline = optionInformation !== null
			&& this.isDisciplineFilterOptionInformation(optionInformation)
			&& optionInformation.parentIdentity !== undefined;

		if (isSubDiscipline) {
			return [];
		}

		const disciplineOptions = this.filterProvider.getFilterOptionInformation(FilterKey.DISCIPLINES);

		const selectedChildren = disciplineOptions.filter(option => {
			const isDiscipline = this.isDisciplineFilterOptionInformation(option);
			return isDiscipline && option.parentIdentity === value && selected.includes(option.optionValue);
		});

		return selectedChildren.map(option => {
			return {
				key: FilterKey.DISCIPLINES,
				value: option.optionValue
			};
		});
	}

	public getParentValueFor(value: string): IFilterPayload | null {
		const info = this.filterProvider.getFilterOptionInformationById(FilterKey.DISCIPLINES, value);
		if (info === null) {
			return null;
		}
		const isSubDiscipline = this.isDisciplineFilterOptionInformation(info) && info.parentIdentity !== undefined;
		if (isSubDiscipline) {
			return {
				key: FilterKey.DISCIPLINES,
				value: info.parentIdentity
			};
		}
		return null;
	}

	private isDisciplineFilterOptionInformation(info: FilterOptionInformation): info is DisciplineFilterOptionInformation {
		return 'parentIdentity' in info;
	}
}
