import { FilterKey } from '../FilterKey';
import { ClearFilterTag } from './ClearFilterTag';
import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

export class OrganisationClearFilterTag extends ClearFilterTag {

	public constructor(
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProviderEnhanced
	) {
		super(FilterKey.ORGANISATIONS, values, required, disabled, filterProvider);
	}

	protected override getDisplayNameForSingleValue(): string {
		return 'University';
	}

	protected override async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilters([this.key, FilterKey.COUNTRY, FilterKey.AREA, FilterKey.CONTINENT]);
	}
}
