import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fac4aea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "SuggestionSectionTitle"
}
const _hoisted_2 = {
  class: "SuggestionSection",
  ref: "suggestionsRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectionSuggestion = _resolveComponent("SelectionSuggestion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasSuggestions)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SelectionSuggestion, { selectionKey: _ctx.disciplineFilterKey }, null, 8 /* PROPS */, ["selectionKey"])
    ], 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}