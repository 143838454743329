import { computed, ComputedRef, ref } from 'vue';
import SelectedFilters from './SelectedFilters.class';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { Action } from '@studyportals/datalake-event-tracker';
import { ITrackingPayload } from '../tracking/ITrackingPayload';

export class SelectedFiltersTracking {

	public constructor(
		private readonly selectedFilters: SelectedFilters,
		private readonly displaySection: string | undefined
	) { }

	public clearAllTrackingData = computed(() => {
		if (!this.selectedFilters.clearAllButtonRef.value) return;

		return new TrackingInput(
			ref(this.selectedFilters.clearAllButtonRef.value),
			this.selectedFilters.stringOfUniqueTagFilterKeys,
			ref(Action.CLEAR),
			ControlEvent.CLICK,
			'clear_all'
		);
	});

	public tagTrackingPayload: ComputedRef<ITrackingPayload[]> = computed(() => {
		const tags = this.selectedFilters.tags.value;

		return tags.map((tag) => {
			let label: string = tag.key;
			if (this.displaySection) {
				label = `${this.displaySection}|${label}`;
			}
			return {
				dataRef: ref(tag.values.join(',')),
				action: ref(Action.CLEAR),
				label
			};
		});
	});
}
