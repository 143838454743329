import { FilterKey } from '../FilterKey';
import { FilterTitle } from '@/presentation/FilterTitle';
import { Tag } from './Tag';
import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

export abstract class FilterTag extends Tag {

	public readonly key: FilterKey;
	public readonly values: readonly string[];
	public readonly count: number;

	public constructor(
		key: FilterKey,
		values: readonly string[],
		required: boolean,
		disabled: boolean,
		filterProvider: IFilterProviderEnhanced,
	) {
		super(required, disabled, filterProvider);

		this.key = key;
		this.values = values;
		this.count = this.countFilters();
	}

	public abstract doClick(): Promise<void>;

	protected getDisplayNameforMultipleValues(): string {
		return FilterTitle.instance.getFor(this.key);
	}

	protected getDisplayNameForSingleValue(value: string): string {
		const option = this.filterProvider.getFilterOptionInformationById(this.key, value);
		return option?.optionShortTitle ?? option?.optionName ?? '';
	}

	private countFilters(): number {
		if (this.disabled || this.required) {
			return 0;
		}
		return this.values.length;
	}

	protected constructDisplayName(): string {
		if (this.values.length === 1) {
			return this.getDisplayNameForSingleValue(this.values[0]);
		}
		return this.getDisplayNameforMultipleValues();
	}

	protected constructId(): string {
		return `${this.key}-${this.values.join('-')}`;
	}
}
