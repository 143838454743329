import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterHierarchy } from "./FilterHierarchy";
import { FilterKey } from "../FilterKey";
import { AreaFilterOptionInformation } from "../AreaFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterOptionInformation } from "../FilterOptionInformation";

export class RegionHierarchy extends FilterHierarchy {

	public constructor(
		filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider
	) {
		super(FilterKey.AREA, FilterKey.COUNTRY, [], filterProvider, locationFilterProvider);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public getSelectedChildrenForAllLevels(_value: string): IFilterPayload[] {
		return [];
	}

	public getParentValueFor(value: string): IFilterPayload | null {
		const info = this.filterProvider.getFilterOptionInformationById(FilterKey.AREA, value);
		if (info === null) {
			return null;
		}
		if (!this.isAreaFilterOptionInformation(info) || !info.countryIdentity) {
			return null;
		}

		return {
			key: FilterKey.COUNTRY,
			value: info.countryIdentity
		};
	}

	private isAreaFilterOptionInformation(info: FilterOptionInformation): info is AreaFilterOptionInformation {
		return 'countryIdentity' in info;
	}
}
