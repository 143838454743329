import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterKey } from "@/models/FilterKey";
import { FilterOptionInformation } from "@/models/FilterOptionInformation";
import { ISuggestionPayload } from "@/models/suggestions/ISuggestionPayload";
import { Product } from "@studyportals/datalake-event-tracker";
import { EventAggregationService } from "./EventAggregationService";
import { FilterStateChangedEvent } from "@/events/FilterStateChangedEvent";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class FilterProviderEnhanced implements IFilterProviderEnhanced {

	private readonly eventAggregationService: EventAggregationService;

	public readonly trackedProduct: Product;
	public readonly displayFacets: boolean;

	public constructor(private readonly filterProvider: IFilterProvider) {
		this.trackedProduct = filterProvider.trackedProduct;
		this.displayFacets = filterProvider.displayFacets;

		this.eventAggregationService = EventAggregationService.instance;
	}

	public async applySuggestion(payload: ISuggestionPayload): Promise<void> {
		if (!this.filterProvider.applySuggestion) {
			throw new Error('FilterProvider does not implement applySuggestion method');
		}
		try {
			await this.filterProvider.applySuggestion(payload);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}

	public async getCountOfResultsForSuggestion(payload: ISuggestionPayload): Promise<number> {
		if (!this.filterProvider.getCountOfResultsForSuggestion) {
			throw new Error('FilterProvider does not implement getCountOfResultsForSuggestion method');
		}
		return this.filterProvider.getCountOfResultsForSuggestion(payload);
	}

	public getFilterKeySelection(): readonly FilterKey[] {
		return this.filterProvider.getFilterKeySelection();
	}

	public getFilterSelection(key: FilterKey): readonly string[] {
		return this.filterProvider.getFilterSelection(key);
	}

	public getFilterOptionInformation(key: FilterKey): readonly FilterOptionInformation[] {
		return this.filterProvider.getFilterOptionInformation(key);
	}

	public getFilterOptionInformationById(key: FilterKey, id: string): FilterOptionInformation | null {
		return this.filterProvider.getFilterOptionInformationById(key, id);
	}

	public getFacet(key: FilterKey, optionValue: string): number | null {
		return this.filterProvider.getFacet(key, optionValue);
	}

	public isFilterCollapsed(key: FilterKey): boolean {
		return this.filterProvider.isFilterCollapsed(key);
	}

	public isFilterRequired(key: FilterKey): boolean {
		return this.filterProvider.isFilterRequired(key);
	}

	public toggleFilterExpandability(key: FilterKey): void {
		this.filterProvider.toggleFilterExpandability(key);
	}
	public async processFilterSelection(payload: IFilterPayload): Promise<void> {
		try {
			await this.filterProvider.processFilterSelection(payload);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}

	public async processFiltersSelection(payload: IFilterPayload[]): Promise<void> {
		try {
			await this.filterProvider.processFiltersSelection(payload);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}

	public async clearFilter(key: FilterKey): Promise<void> {
		try {
			await this.filterProvider.clearFilter(key);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}

	public async clearFilters(keys: FilterKey[]): Promise<void> {
		try {
			await this.filterProvider.clearFilters(keys);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}

	public async clearSelection(payload: IFilterPayload[]): Promise<void> {
		try {
			await this.filterProvider.clearSelection(payload);
		}
		finally {
			this.eventAggregationService.publish(new FilterStateChangedEvent());
		}
	}
}
