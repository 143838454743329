import { FilterKey } from '@/models/FilterKey';
import { OrganisationClearFilterTag } from '@/models/tags/OrganisationClearFilterTag';
import { FilterTag } from '@/models/tags/FilterTag';
import { TuitionFeeClearFilterTag } from '@/models/tags/TuitionFeeClearFilterTag';
import { KeywordClearFilterTag } from '@/models/tags/KeywordClearFilterTag';
import { ClearFilterTag } from '@/models/tags/ClearFilterTag';
import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

export class SelectedFiltersTagFactory {
	private static readonly MAX_NUMBER_OF_INDIVIDUAL_TAGS = 9;

	private readonly eligibleKeysForTags = [
		FilterKey.KEYWORD_WHAT,
		FilterKey.KEYWORD_WHERE,
		FilterKey.ORGANISATIONS,
		FilterKey.DISCIPLINES,
		FilterKey.EDUCATION_LEVEL,
		FilterKey.CONTINENT,
		FilterKey.COUNTRY,
		FilterKey.AREA,
		FilterKey.TUITION_FEE,
		FilterKey.DURATION,
		FilterKey.ATTENDANCE,
		FilterKey.DELIVERY_METHOD,
		FilterKey.DEGREE_TYPE,
		FilterKey.EDUCATIONAL_FORM,
		FilterKey.SPECIAL_PROGRAMMES,
	];

	public constructor(private readonly filterProvider: IFilterProviderEnhanced) { }

	public constructFor(key: FilterKey): FilterTag[] {
		if (!this.eligibleKeysForTags.includes(key)) {
			return [];
		}

		const tags: FilterTag[] = [];
		const required = this.filterProvider.isFilterRequired(key);
		const values = this.filterProvider.getFilterSelection(key);

		const disabled = this.isDisabled(required, values.length);

		if (this.hasMoreValuesThanMaxAllowed(values.length)) {
			tags.push(this.construct(key, values, required, disabled));
			return tags;
		}

		values.forEach((value) => {
			tags.push(this.construct(key, [value], required, disabled));
		});

		return tags;
	}

	public construct(key: FilterKey, values: readonly string[], required: boolean, disabled: boolean): ClearFilterTag {
		if (key === FilterKey.TUITION_FEE) {
			return new TuitionFeeClearFilterTag(values, required, disabled, this.filterProvider);
		}
		if (key === FilterKey.ORGANISATIONS) {
			return new OrganisationClearFilterTag(values, required, disabled, this.filterProvider);
		}
		if (key === FilterKey.KEYWORD_WHAT || key === FilterKey.KEYWORD_WHERE) {
			return new KeywordClearFilterTag(key, values, required, disabled, this.filterProvider);
		}

		return new ClearFilterTag(key, values, required, disabled, this.filterProvider);
	}

	private hasMoreValuesThanMaxAllowed(valueCount: number): boolean {
		return valueCount > SelectedFiltersTagFactory.MAX_NUMBER_OF_INDIVIDUAL_TAGS;
	}

	private isDisabled(required: boolean, valueCount: number): boolean {
		return required && valueCount === 1;
	}
}
