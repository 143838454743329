import { FilterKey } from "../FilterKey";
import { FilterTag } from "./FilterTag";
import { IFilterPayload } from "@/interfaces/IFilterPayload";
import { FilterHierarchyFactory } from "../hierarchy/FilterHierarchyFactory";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export class SuggestionFilterTag extends FilterTag {

	public toSelect: IFilterPayload;
	public toDeselect: FilterKey[];

	public constructor(
		key: FilterKey,
		values: readonly string[],
		filterProvider: IFilterProviderEnhanced,
		locationFilterProvider: ILocationFilterProvider,
	) {
		super(key, values, false, false, filterProvider);

		if (this.values.length === 0) {
			throw new Error(`SelectFilterTag: ${this.key} tag values are empty`);
		}

		this.toSelect = { key, value: values[0] };

		const filterHierarchyFactory = new FilterHierarchyFactory(filterProvider, locationFilterProvider);
		const childrenKeys = filterHierarchyFactory.getFor(key).getChildrenKeys();
		this.toDeselect = [...new Set([this.key, ...childrenKeys])];
	}

	public async doClick(): Promise<void> {
		await this.applySuggestion();
	}

	private async applySuggestion(): Promise<void> {
		await this.filterProvider.applySuggestion({
			toSelect: [this.toSelect],
			toDeselect: this.toDeselect
		});
	}
}
