import { FilterKey } from '../FilterKey';
import { TuitionFeeInterval } from '@/presentation/tuition-fee/TuitionFeeInterval';
import { ClearFilterTag } from './ClearFilterTag';
import { IFilterProviderEnhanced } from '@/interfaces/filter-providers/IFilterProviderEnhanced';

export class TuitionFeeClearFilterTag extends ClearFilterTag {
	public constructor(
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProviderEnhanced
	) {
		super(FilterKey.TUITION_FEE, values, required, disabled, filterProvider);
	}

	protected override getDisplayNameForSingleValue(value: string): string {
		const interval = new TuitionFeeInterval(value);

		if (interval.hasMaxOnly) {
			return `Tuition fee max`;
		}
		if (interval.hasMinOnly) {
			return `Tuition fee min`;
		}
		return `Tuition fee`;
	}

	protected override async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilter(this.key);
	}
}
