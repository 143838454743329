import { FilterKey, filterKeysOfLocation } from "../FilterKey";
import { Suggestion } from "./Suggestion";
import { CustomTag } from "../tags/CustomTag";
import { computed, ComputedRef, ref } from 'vue';
import { ITrackingPayload } from "@/presentation/tracking/ITrackingPayload";
import { Action } from "@studyportals/datalake-event-tracker";
import { ISuggestionPayload } from "./ISuggestionPayload";

export class AllAroundTheWorldSuggestion extends Suggestion<CustomTag> {

	protected buildSuggestionTag(): CustomTag | null {
		const isContinentSelected = this.filterProvider.getFilterSelection(FilterKey.CONTINENT).length > 0;
		const isCountrySelected = this.filterProvider.getFilterSelection(FilterKey.COUNTRY).length > 0;
		const isAreaSelected = this.filterProvider.getFilterSelection(FilterKey.AREA).length > 0;
		const isLocationSelected = isContinentSelected || isCountrySelected || isAreaSelected;

		if (!isLocationSelected) {
			return null;
		}

		const click = async (): Promise<void> => {
			const payload = this.buildSuggestionPayload();

			await this.filterProvider.applySuggestion(payload);
		};
		return new CustomTag(false, false, this.filterProvider, 'All around the world', click);
	}

	protected buildSuggestionPayload(): ISuggestionPayload {
		return {
			toSelect: [],
			toDeselect: [...filterKeysOfLocation]
		};
	}

	protected getTagIcon(): string {
		return 'lnr-map-marker';
	}

	public trackingPayload: ComputedRef<ITrackingPayload> = computed(() => {
		return {
			dataRef: ref(''),
			action: ref(Action.SELECT),
			label: 'suggestion|anywhere'
		};
	});
}
