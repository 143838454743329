import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!

  return (_ctx.canShow)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "SuggestionContainer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.apply()))
      }, [
        _createVNode(_component_Tag, {
          tag: _ctx.suggestion.tag,
          icon: _ctx.suggestion.tagIcon,
          trackingPayload: _ctx.suggestion.trackingPayload,
          trackClick: true,
          ref: "tagRef"
        }, null, 8 /* PROPS */, ["tag", "icon", "trackingPayload"]),
        _createElementVNode("span", {
          class: "ShowResults",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.apply($event)))
        }, "Show " + _toDisplayString(_ctx.count) + " " + _toDisplayString(_ctx.count === 1 ? 'result' : 'results'), 1 /* TEXT */)
      ]))
    : _createCommentVNode("v-if", true)
}