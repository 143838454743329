import { computed, Ref } from 'vue';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { ITrackingInput } from '../../presentation/tracking/ITrackingInput';
import { Action } from '@studyportals/datalake-event-tracker';

export class TrackingInput implements ITrackingInput {

	public constructor(
		public readonly elementRef: Ref<Element | null>,
		private readonly dataRefOriginal: Ref<string>,
		public readonly action: Ref<Action>,
		public readonly eventToSubscribe: ControlEvent,
		public readonly label: string | undefined
	) {
		this.label = label?.replace(/-/g, '_');
		this.dataRefOriginal = dataRefOriginal;
	}

	public dataRef = computed(() => this.dataRefOriginal.value?.replace(/-/g, '_'));
}
