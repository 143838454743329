import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-581693de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "Badge"
}
const _hoisted_2 = { class: "FilterName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tracking = _resolveComponent("Tracking")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["FilterTag", { Disabled: _ctx.tag.disabled }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tag.onClick($event))),
      ref: "tagRef"
    }, [
      (_ctx.tag.count > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.tag.count), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.tag.displayName), 1 /* TEXT */),
      _createElementVNode("i", {
        class: _normalizeClass(["ButtonIcon", _ctx.iconClass])
      }, null, 2 /* CLASS */)
    ], 2 /* CLASS */),
    (_ctx.trackingData)
      ? (_openBlock(), _createBlock(_component_Tracking, {
          key: 0,
          data: _ctx.trackingData
        }, null, 8 /* PROPS */, ["data"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}