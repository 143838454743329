import { Tag } from "../tags/Tag";
import { ITrackingPayload } from "@/presentation/tracking/ITrackingPayload";
import { ComputedRef } from "vue";
import { ISuggestionPayload } from "./ISuggestionPayload";
import { IFilterProviderEnhanced } from "@/interfaces/filter-providers/IFilterProviderEnhanced";

export abstract class Suggestion<T extends Tag> {
	private _tag: T | null = null;
	private _resultsCount: number | null = null;
	private _tagIcon: string | null = null;

	public constructor(
		protected readonly filterProvider: IFilterProviderEnhanced,
	) { }

	public get tag(): T | null {
		if (!this._tag) {
			this._tag = this.buildSuggestionTag();
		}
		return this._tag;
	}

	public get tagIcon(): string | null {
		if (!this._tagIcon) {
			this._tagIcon = this.getTagIcon();
		}
		return this._tagIcon;
	}

	public async getResultsCount(): Promise<number> {
		if (this._resultsCount === null) {
			this._resultsCount = await this.countResults();
		}
		return this._resultsCount;
	}

	public abstract trackingPayload: ComputedRef<ITrackingPayload>;

	protected abstract buildSuggestionTag(): T | null;

	protected abstract buildSuggestionPayload(): ISuggestionPayload;

	protected abstract getTagIcon(): string;

	protected async countResults(): Promise<number> {
		const payload = this.buildSuggestionPayload();

		return await this.filterProvider.getCountOfResultsForSuggestion(payload) ?? 0;
	}
}
