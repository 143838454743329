<template>
	<div class="FilterTag" :class="{ Disabled: tag.disabled }" @click="tag.onClick($event)" ref="tagRef">
		<span class="Badge" v-if="tag.count > 1">{{ tag.count }}</span>
		<span class="FilterName">{{ tag.displayName }}</span>
		<i class="ButtonIcon" :class="iconClass"></i>
	</div>
	<Tracking v-if="trackingData" :data="trackingData"></Tracking>
</template>

<script lang="ts" src="./Tag.ts"></script>
<style lang="scss" src="./Tag.scss" scoped></style>
