import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "../FilterKey";
import { CountryHierarchy } from "./CountryHierarchy";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { ContinentHierarchy } from "./ContinentHierarchy";
import { DisciplineHierarchy } from "./DisciplineHierarchy";
import { NoHierarchy } from "./NoHierarchy";
import { RegionHierarchy } from "./RegionHierarchy";
import { IFilterHierarchy } from "./IFilterHierarchy";

export class FilterHierarchyFactory {

	public constructor(private readonly filterProvider: IFilterProvider, private readonly locationFilterProvider: ILocationFilterProvider) { }

	public getFor(key: FilterKey): IFilterHierarchy {
		switch (key) {
		case FilterKey.CONTINENT:
			return new ContinentHierarchy(this.filterProvider, this.locationFilterProvider);
		case FilterKey.COUNTRY:
			return new CountryHierarchy(this.filterProvider, this.locationFilterProvider);
		case FilterKey.AREA:
			return new RegionHierarchy(this.filterProvider, this.locationFilterProvider);
		case FilterKey.DISCIPLINES:
			return new DisciplineHierarchy(this.filterProvider, this.locationFilterProvider);
		default:
			return new NoHierarchy(key, this.filterProvider, this.locationFilterProvider);
		}
	}
}
