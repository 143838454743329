<template>
	<span class="SuggestionSectionTitle" v-if="hasSuggestions">{{title}}</span>
	<div class="SuggestionSection" ref="suggestionsRef">
		<SelectionSuggestion :selectionKey="regionFilterKey" />
		<SelectionSuggestion :selectionKey="countryFilterKey" />
		<SelectionSuggestion :selectionKey="continentFilterKey" />
	</div>
</template>

<script lang="ts" src="./LocationSuggestions.ts"></script>
<style lang="scss" src="./LocationSuggestions.scss" scoped></style>
